<script lang="ts">
	import { buttonSizeClass, type ButtonSize } from '$lib/utils/button-styles'
	import Spinner from '$lib/components/loading/Spinner.svelte'
	import { createEventDispatcher } from 'svelte'
	import { override } from '$src/utils/classnames'
	import { twMerge } from 'tailwind-merge'

	export let label = null
	export let size: ButtonSize = 'md'
	export let priority: 'primary' | 'secondary' | 'tertiary' = 'primary'
	export let theme: 'brand' | 'dark' | 'light' | 'delete' = 'brand'
	export let loading = false
	let className: string = ''
	export { className as class }
	let sizeClass = buttonSizeClass(size)
	export let disabled: boolean = false

	const { className: _, ...props } = $$restProps

	const dispatch = createEventDispatcher()

	function handleOnClick(event: MouseEvent) {
		event.preventDefault()
		event.stopPropagation()
		dispatch('click')
	}

	const sharedClasses =
		'flex flex-row items-center justify-center appearance-none transition-all hover:opacity-100 text-center rounded-md whitespace-nowrap leading-4 focus:outline-none'

	$: priorityClasses =
		priority == 'primary'
			? 'tracking-wide font-normal'
			: priority == 'secondary'
			? 'normal-case text-xl font-normal tracking-wide'
			: ''
	$: themeClasses =
		theme == 'brand'
			? 'hover:bg-brand-primary bg-brand-primary-75d text-white active:text-white disabled:bg-brand-gray-3 disabled:text-black disabled:from-transparent disabled:to-transparent disabled:cursor-not-allowed'
			: theme == 'dark'
			? 'hover:bg-brand-gray-5 bg-black border border-black text-white active:text-white disabled:bg-brand-gray-3 disabled:text-brand-gray-5 active:bg-brand-gray-5'
			: theme == 'light'
			? 'bg-transparent hover:bg-white text-black border border-black active:text-black disabled:bg-brand-gray-3 disabled:text-brand-gray-5 active:bg-brand-gray-5'
			: theme == 'delete'
			? 'bg-red-700 hover:bg-red-600 text-white active:text-white disabled:bg-red-400 disabled:text-white active:bg-red-600'
			: ''

	$: computedClassName = twMerge(override(sharedClasses, className))
</script>

<button
	on:click={handleOnClick}
	{...props}
	{disabled}
	class={`${computedClassName}
          ${priorityClasses}
          ${themeClasses}
          ${sizeClass}
          ${className}
          `}
>
	{#if loading}
		<span class="pr-2 -my-1">
			<Spinner {size} embedded />
		</span>
	{/if}
	<slot>{label}</slot>
</button>
